import api from "services/api";
import { app_send_facebook_conversion_path } from "utils/routes";

const sendFacebookConversion = (conversionParams) => {
  const { event_name, user_email, customer_id, uuid } = conversionParams;

  return api.post(app_send_facebook_conversion_path(), {
    event_name,
    user_email,
    customer_id,
    uuid,
  });
};

export default sendFacebookConversion;
